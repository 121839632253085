import React from 'react'
import styled from 'styled-components'
import { colors} from '../styles/colors'
import { GreyText, MediumTitle, SmallTitle } from '../styles/fonts'
import { bp } from '../styles/colors'
import Img from "gatsby-image"
import { FaArrowRight } from 'react-icons/fa'
import ProjectPopup from "../components/projectPopup";
import { useState } from "react";

const FeaturedProject = (props) => {

    const [popOpen, setPopOpen] = useState(null);


const projectList = props.projectData.allMarkdownRemark.nodes

const getProjectImages = (item) => {
    const data = item.frontmatter;
    const images = [];

    if (data.bigImg) {
      images.push(data.bigImg);
    }

    if (data.smallImg2) {
      images.push(data.smallImg2);
    }

    if (data.smallImg3) {
      images.push(data.smallImg3);
    }

    return images;
  };

    return (
      <Wrap>
        <Wrapper>
            <BigTitle>Projektit</BigTitle>
        <FeaturedContainer>

        <ImagesContainer>
            <ImageBox
              className='item1'
                style={{width: "100%"}}
                key={projectList[0].frontmatter.bigImg.childImageSharp.id}
                fluid={projectList[0].frontmatter.bigImg.childImageSharp.fluid}
            />

            <ImageBox
            className='item2'
                style={{width: "100%"}}
                key={projectList[0].frontmatter.smallImg2.childImageSharp.id}
                fluid={projectList[0].frontmatter.smallImg2.childImageSharp.fluid}
            />
            <ImageBox
            className='item3'
                style={{width: "100%"}}
                key={projectList[0].frontmatter.smallImg3.childImageSharp.id}
                fluid={projectList[0].frontmatter.smallImg3.childImageSharp.fluid}
            />
        </ImagesContainer>

        <TextContainer>
            <FeaturedTag>Esittelyssä</FeaturedTag>
            <SmallTitle>{projectList[0].frontmatter.location}</SmallTitle>
            <MediumTitle lang='fi'>{projectList[0].frontmatter.medTitle}</MediumTitle>
            <GreyText>{projectList[0].frontmatter.description}</GreyText>
            <ReadMore onClick={(e) => {
                
                props.onClick(projectList[0]);
              }}>Lue lisää<ArrowButton/></ReadMore>
            </TextContainer>

        </FeaturedContainer>
        <Line></Line>

        {/**POP UP HANDLER*/}
      {popOpen == null ? (
        <></>
      ) : (
        <ProjectPopup
          data-type-="popupElem"
          smallTitle={popOpen.frontmatter.smallTitle}
          medTitle={popOpen.frontmatter.medTitle}
          smallDesc={popOpen.frontmatter.smallDesc}
          location={popOpen.frontmatter.location}
          listElements={[...popOpen.frontmatter.listElements]}
          description={popOpen.frontmatter.description}
          images={getProjectImages(popOpen)}
          onClick={() => setPopOpen(null)}
        />
      )}
        </Wrapper>
        </Wrap>
    )
}

const BigTitle = styled.h1`
    font-size: 12rem;
    font-weight: 900;
    color: ${colors.grey7};
    width: 100%;
	transform: translate(-10px, 3rem);
    @media screen and (max-width: ${bp.mobile}) {
        font-size: 24vw;
        transform: translate(-5px, 6vw)
    }
  `

const ArrowButton = styled(FaArrowRight)`
    transform: translate(6px, 2px);
    transition: all 0.7s;
`

const ReadMore = styled.p`
    margin-top: 1rem;
    border-radius: 0px;
    color: ${colors.black};
    font-weight: bold;
    font-size: 1.1rem;
    
    width: fit-content;
    cursor: pointer;

    &:hover {
        ${ArrowButton} {
            transform: translate(12px, 2px);
        }
    }
`


const FeaturedTag = styled.p`
    color: ${colors.black};
    background-color: ${colors.grey7};
    width: max-content;
    padding: 0.8rem 1rem 0.8rem 1rem;
    border-radius: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
`

const ImagesContainer = styled.div`
  flex-basis: 60%;
  flex-grow: 0.6;
  width: 100%;
	display: grid;
	grid-template-columns: [line1] 1fr [line2] 1fr [line3] 1fr  [end];
  	grid-template-rows: [row1] 1fr [row2] 1fr [row-end];
    gap: 30px;

     @media screen and (max-width: ${bp.mobile}) {
         flex-basis: 100%;
         gap: 15px;
     }

    .item1{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;

    }

    .item2{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      
    }

    .item3 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;

    }
`;

const ImageBox = styled(Img)`
  
`;

// const ImageContainer = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     flex-basis: 60%;
//     gap: 2rem;

//     @media screen and (max-width: ${bp.mobile}) {
//         flex-basis: 100%;
//         gap: 1rem;
//     }
// `

// const ImageBox = styled(Img)`
  
   
//     height: fit-content;
//     flex-grow: 1;

//     @media screen and (max-width: ${bp.mobile}) {
//         min-width: 100%;
//     }
// `

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-basis:30%;
    font-size: 1rem;
    overflow: hidden;
    min-width: 23rem;
 

    @media screen and (max-width: ${bp.mobile}) {
        flex-basis:100%;
    }
`

const Line = styled.div`
    background-color: ${colors.grey5};
    height: 0.05rem;
    width: 100%;
    margin: 3rem 0 3rem 0;
`

const FeaturedContainer = styled.section`
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
    flex-wrap: wrap;

`

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: ${bp.large};
`

const Wrap = styled.section`
  display: flex;
  margin: 0 5rem 0 5rem;
  justify-content: center;

  @media screen and (max-width: ${bp.mobile}) {
        margin: 0 1rem 0 1rem;
    }
`

export default FeaturedProject