import React from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";
import { MediumTitle, SmallMediumTitle, SmallTitle } from "../styles/fonts";
import { bp } from "../styles/colors";
import { graphql, useStaticQuery } from "gatsby";

const OldProjects = () => {
    const projectSimple = useStaticQuery(graphql`
        query ProjectSimple {
            allMarkdownRemark(
                filter: {
                    frontmatter: { templateKey: { eq: "projectsimple" } }
                }
                sort: { fields: frontmatter___yearOfManf, order: DESC }
            ) {
                nodes {
                    frontmatter {
                        nameorloc
                        smallDesc
                        yearOfManf
                    }
                }
            }
        }
    `);

    return (
        <Wrap>
            <ContentWrapper>
                <Wrapper>
                    <SmallTitle style={{ marginBottom: "1rem" }}>
                        Rakennusurakoita monipuolisesti
                    </SmallTitle>
                    <MediumTitle style={{ marginBottom: "3rem" }}>
                        Muita uusimpia <br /> projektejamme
                    </MediumTitle>

                    <TextWrapper>
                        <Line />

                        {projectSimple.allMarkdownRemark.nodes.map(
                            (node, index) => (
                                <div key={index}>
                                    <TextBox>
                                        <TextContainer>
                                            <ListFont>
                                                {node.frontmatter.nameorloc}
                                            </ListFont>
                                            <ListFont
                                                style={{
                                                    fontWeight: 100,
                                                    color: colors.grey4,
                                                }}
                                            >
                                                {node.frontmatter.smallDesc}
                                            </ListFont>
                                        </TextContainer>
                                        <ListFont style={{ fontWeight: 100 }}>
                                            {node.frontmatter.yearOfManf}
                                        </ListFont>
                                    </TextBox>
                                    <Line />
                                </div>
                            )
                        )}
                    </TextWrapper>
                </Wrapper>
            </ContentWrapper>
        </Wrap>
    );
};

const ListFont = styled(SmallMediumTitle)`
    font-size: 1.3rem;
    line-height: 135%;

    @media screen and (max-width: ${bp.mobile}) {
        font-size: 1.1rem;
        line-height: 135%;
    }
`;

const Line = styled.div`
    background-color: ${colors.grey5};
    height: 1px;
    width: 100%;
    margin: 2.5rem 0 2.5rem 0;

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
        margin: 1.5rem 0 1.5rem 0;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: ${bp.mobile}) {
        width: unset;
        margin: -2rem 0 0 0;
    }
`;

const TextContainer = styled.div`
    display: flex;
    gap: 2rem;

    @media screen and (max-width: ${bp.mobile}) {
        gap: 1rem;
    }
`;

const TextBox = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    max-width: ${bp.large};
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    overflow: hidden;
    max-width: ${bp.large};

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
    }
`;

const Wrap = styled.section`
    display: flex;
    margin: 0 5rem 7rem 5rem;
    justify-content: center;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 0 1rem 4rem 1rem;
    }
`;

export default OldProjects;
