import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import ProjectPopup from "../components/projectPopup";
import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { bp } from "../styles/colors";
import ProjectCard from "../components/projectCard";
import FeaturedProject from "../components/featuredProject";
import ProjectHero from "../components/projectHero";
import OldProjects from "../components/oldProjects";
import Seo from "../components/Seo";

const ToitammePage = () => {
    const [popOpen, setPopOpen] = useState(null);

    if (typeof window !== "undefined" && window.document) {
        if (popOpen !== null) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }

    const projectData = useStaticQuery(graphql`
        query Project {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "project" } } }
                sort: { order: DESC, fields: frontmatter___smallDesc }
            ) {
                nodes {
                    frontmatter {
                        description
                        title
                        listElements {
                            listElement
                        }
                        location
                        medTitle
                        projectDesv
                        smallDesc
                        smallTitle
                        bigImg {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        smallImg2 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        smallImg3 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                    }
                    id
                }
            }
            image: file(relativePath: { eq: "square/Ylitalo-28.jpg" }) {
                publicURL
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const projectList = projectData.allMarkdownRemark.nodes;

    const getProjectImages = (node) => {
        const data = node.frontmatter;
        const images = [];

        if (data.bigImg) {
            images.push(data.bigImg);
        }

        if (data.smallImg2) {
            images.push(data.smallImg2);
        }

        if (data.smallImg3) {
            images.push(data.smallImg3);
        }

        return images;
    };

    return (
        <Layout>
            <Seo
                title="Referenssit | Ylitalon"
                description="Talon rakentamisesta LVI-saneerauksiin. Olemme toteuttaneet ammattitaidolla ja intohimolla lukuisia erilaisia rakennusurakoita Lahden alueella."
                keywords={[
                    "omakotitalo",
                    "omakotitalot",
                    "miniomakotitalo",
                    "Lahti",
                    "Lahden talousalue",
                    "Porvoo",
                    "Orimattila",
                    "Hollola",
                ]}
                url="https://ylitalon.netlify.app/toitamme"
                image={
                    "https://ylitalon.netlify.app" + projectData.image.publicURL
                }
            />
            <ProjectHero
                smallTitle="Olemme ylpeitä jäljestämme"
                mediumTitle="Ammattitaidolla toteutetut työmme"
                bigTitle="Töitämme"
            />
            <FeaturedProject
                projectData={projectData}
                popOpen={popOpen}
                onClick={(e) => {
                    setPopOpen(e);
                }}
            />
            <ProjectShow>
                <ProjectShowWrapper>
                    {/**PRINT ALL PROJECTS*/}
                    {projectList.map((n) => (
                        <ProjectCard
                            key={n.id}
                            onClick={() => {
                                setPopOpen(n);
                            }}
                            fluidImg={
                                n.frontmatter.bigImg.childImageSharp.fluid
                            }
                            title={n.frontmatter.title}
                            projectDesv={n.frontmatter.projectDesv}
                        ></ProjectCard>
                    ))}
                </ProjectShowWrapper>
            </ProjectShow>

            {
                /**POP UP HANDLER*/
                popOpen && (
                    <ProjectPopup
                        data-type-="popupElem"
                        smallTitle={popOpen.frontmatter.smallTitle}
                        medTitle={popOpen.frontmatter.medTitle}
                        smallDesc={popOpen.frontmatter.smallDesc}
                        location={popOpen.frontmatter.location}
                        listElements={[...popOpen.frontmatter.listElements]}
                        description={popOpen.frontmatter.description}
                        images={getProjectImages(popOpen)}
                        onClick={() => setPopOpen(null)}
                        id={popOpen.id}
                    />
                )
            }
            <OldProjects />
        </Layout>
    );
};

const ProjectShowWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5.4rem 5.4rem;
    max-width: ${bp.large};
    width: 100%;

    @media screen and (max-width: ${bp.mobile}) {
        justify-content: center;
        gap: 3rem;
    }
`;

const ProjectShow = styled.section`
    justify-content: center;
    display: flex;
    margin: 5rem 5rem 10rem 5rem;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 1rem 1rem 5rem 1rem;
    }
`;

export default ToitammePage;
