import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles/colors'
import Img from 'gatsby-image'
import { bp } from '../styles/colors'
import {
    FaArrowRight
} from 'react-icons/fa'

const ProjectCard = (props) => {

    const button = props.button

    return (
        
        <ProjectCardContainer onClick={props.onClick} id={props.id}>
            <ProjectImgWrapper>
                <ProjectImg fluid={props.fluidImg}/>
            </ProjectImgWrapper>
            <ProjectName>{props.title}</ProjectName>
            <ProjectDesc>{props.projectDesv}</ProjectDesc>
            {
                button == null
                ? <ReadMore>Lue lisää<ArrowButton/></ReadMore>
                : button
            }
        </ProjectCardContainer>
    )
}

const ArrowButton = styled(FaArrowRight)`
    transform: translate(6px, 2px);
    transition: all 0.7s;
    -webkit-tap-highlight-color: transparent;

`
const ProjectImg = styled(Img)`
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;

    transition: 0.7s ease;

`

const ProjectCardContainer = styled.div`
    min-width: 18rem;
    width: 23rem;
    max-width: 21rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
    background: white;
    overflow: hidden;
    padding: 1rem;
    box-shadow:      0px 0px 10px rgba(0, 0, 0, 0.05),
  0px 0px 80px rgba(0, 0, 0, 0.1);


  -webkit-tap-highlight-color: transparent;

    &:hover {
        ${ArrowButton} {
            transform: translate(12px, 2px);
        }
        ${ProjectImg} {
            transform: scale(1.05);
            opacity: 1;
            
        }
    }

    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
        min-width: unset;
        max-width: unset;

        &:hover {
        ${ArrowButton} {
            transform: translate(6px, 2px);
        }
        ${ProjectImg} {
            transform: none;
            opacity: 1;
            
        }
    }
    }


    *::selection {
        background-color: transparent;
    }
`


const ProjectDesc = styled.p`
    color: ${colors.grey4};
    font-size: 1.1rem;
    line-height: 135%;
`

const ProjectName = styled.p`
    color: ${colors.black};
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1.5rem 0 0.5rem 0;
`


const ProjectImgWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: black;
`

const ReadMore = styled.p`
    margin-top: 1rem;
    border-radius: 0px;
    color: ${colors.black};
    font-weight: bold;
    font-size: 1.1rem;
    -webkit-tap-highlight-color: transparent;

`

export default ProjectCard