import React from 'react'
import styled from 'styled-components'
import { MediumTitle, SmallTitle } from '../styles/fonts'
import { bp } from '../styles/colors'

const ProjectHero = (props) => {
    return (
        <Wrap>
        <Wrapper>
            <TitleContainer>
            <SmallTitle style={{marginBottom: '1rem'}}>{props.smallTitle}</SmallTitle>
            <MediumTitle>{props.mediumTitle}</MediumTitle>
            </TitleContainer>
        </Wrapper>
        </Wrap>
    )

}

const Wrap = styled.section`
  display: flex;
  margin: 0 0rem 0 0rem;
  justify-content: center;

  @media screen and (max-width: ${bp.mobile}) {
        margin: 0 1rem 0 1rem;
    }
`

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 15rem;
    max-width: ${bp.large};
    width: 100%;
    margin-left: 4.47rem;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: ${bp.mobile}) {
        margin-left: 1rem;
        margin-block-end: 1rem;
        margin-top: 12rem;
    }
`

const TitleContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    max-width: 45%;
    margin-left: 5rem;
    margin-bottom: 3rem;;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 0rem 0rem 0 0rem;
        flex-basis: 100%;
        max-width: 100%;
    }

`

export default ProjectHero